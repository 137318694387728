import axios from "axios"
import { API_BASE_URL } from "config/constants"

import { getCookie } from "config/functions"

axios.defaults.baseURL = API_BASE_URL

const token = getCookie('token')

export const getLibraryDataForCustomer = (id) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-library-data-for-customer', {id});
        resolve(response.data)
    })
}

export const handleAddToFavouriteLibrary = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-add-to-favourite', data);
        resolve(response.data)
    })
}

export const handleAllCourseComplete = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-all-course-complete', data);
        resolve(response.data)
    })
}

export const getSignedUrl = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/admin/get-signed-url', data);
        resolve(response.data)
    })
}

export const getLibraryDataForPublic = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-public-library-data', data);
        resolve(response.data)
    })
}

export const getPublicLibraryDetailForCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-public-library-detail-for-customer', data);
        resolve(response.data)
    })
}

export const getLibraryDetailForNonUsers = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-library-detail-for-non-user', data);
        resolve(response.data)
    })
}

export const handleAssignLibraryToCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
    const response = await axios.post('/customer/assign-library-to-customer', data);
        resolve(response.data)
    })
}

export const sendJoinCommunityMessage = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/send-join-community-message', data);
        resolve(response.data)
    })
}

export const getLibraryLessonDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-library-lesson-detail', data);
        resolve(response.data)
    })
}

export const handleLibraryLessonFavourite = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/submit-customer-library-lesson-favourite', data);
        resolve(response.data)
    })
}

export const sendUnlockLibraryRequestToAdmin = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/send-unlock-library-request-todmin', data);
        resolve(response.data)
    })
}

export const handleRestartLibrary = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-restart-library', data);
        resolve(response.data)
    })
}

export const handleCourseOnDashboard = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-library-in-dashboard', data);
        resolve(response.data)
    })
}

export const getCustomerCardInformation = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/stripe/get-customer-card-information', data);
        resolve(response.data)
    })
}

export const addNewCustomerCardInStripe = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/stripe/add-customer-card-information', data);
        resolve(response.data)
    })
}

export const handleCreatePaymentIntent = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/process-payment-intent', data);
        resolve(response.data)
    })
}

export const createStripeLibraryPaymentLink = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/create-stripe-payment-link', data);
        resolve(response.data)
    })
}

export const createStripeSubscriptionPaymentLink = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/create-stripe-subscription-link', data);
        resolve(response.data)
    })
}

export const handleLibraryBundleAssignToCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-customer-library-purchase', data);
        resolve(response.data)
    })
}

export const getLibrarySubscriptionDataForCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/get-library-subscription-info', data);
        resolve(response.data)
    })
}

export const handleAssignSubscriptionToCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-assign-subscription', data);
        resolve(response.data)
    })
}

export const handleCustomerSubscriptionAssignLibrary = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/handle-assign-subscription-library', data);
        resolve(response.data)
    })
}

export const handleApplyLibraryCouponCode = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/coupon/handle-apply-library-coupon-code', data);
        resolve(response.data)
    })
}

export const addFreeLibraryWithCoupon = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/add-free-library-with-coupon', data);
        resolve(response.data)
    })
}

export const sendVideoLoadError = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/send-video-load-error', data);
        resolve(response.data)
    })
}